import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Backdrop,
  Box,
  Paper,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import { updateMathJax } from "..";
import {
  CalcTable,
  CalcTypeToParse,
  CalcVariable,
  Canvas,
  Comparison,
  ComparisonForced,
  FigureBase,
  Symbolic,
} from "../commonTypes/CalculationRunTypes";
import { BEGIN_ALIGN, END_ALIGN, wrapMathString } from "./CalcReportComponents/reportUtilities";
import CanvasDesigner from "./CanvasDesigner";
import FigureDesigner from "./FigureDesigner";
import ResultTableItem from "./ResultTableItem";

interface Props {
  resultActionButtons: JSX.Element;
  resultItems: CalcTypeToParse[];
  isResultStale: boolean;
  isLoading?: boolean;
}

export default function CalculationResultsView({
  resultActionButtons,
  resultItems,
  isResultStale,
  isLoading,
}: Props) {
  const theme = useTheme();
  return (
    <Box component={Paper} padding="1rem" height="fit-content">
      <Stack direction="row">
        <Typography variant="h4">Results </Typography>
        {resultActionButtons}
      </Stack>
      {isLoading ? (
        <Skeleton variant="rounded" width="100%" height="10rem" />
      ) : (
        <Box minHeight="10rem" sx={{ position: "relative" }}>
          {resultItems.map((item, index) => {
            switch (item.type) {
              case "Calculation":
                const parsedCalc = item as CalcVariable;
                return (
                  <Box
                    key={"calc-description-box-" + index}
                    padding="0.5rem"
                    margin="0.5rem"
                    sx={{
                      borderStyle: "solid",
                      borderRadius: "1rem",
                      borderColor: theme.palette.grey["800"],
                    }}
                  >
                    <Typography key={`calc-description-${index}`}>
                      {parsedCalc.description}
                    </Typography>
                    <Typography key={`calc-result-${index}`} align="center">
                      {wrapMathString(`${parsedCalc.name} ${parsedCalc.resultWithUnit}`)}
                    </Typography>
                  </Box>
                );
              case "Canvas":
                return <CanvasDesigner item={item as Canvas} key={"calc-canvas-" + index} />;
              case "Comparison":
                const check = item as Comparison;
                const substitutedCheck = wrapMathString(
                  `${BEGIN_ALIGN} ${check.substituted} ${END_ALIGN}`
                );
                return (
                  <Box
                    key={"calc-description-box-" + index}
                    padding="0.5rem"
                    margin="0.5rem"
                    sx={{
                      backgroundColor: check.value
                        ? theme.palette.success.light
                        : theme.palette.error.light,
                      borderColor: check.value
                        ? theme.palette.success.main
                        : theme.palette.error.main,
                      borderStyle: "solid",
                      borderRadius: "1rem",
                    }}
                  >
                    <Tooltip
                      key={`check-tooltip-${index}`}
                      title={
                        check.substituted
                          ? `${check.description ?? ""} \n ${substitutedCheck}`
                          : "Check Results"
                      }
                      onOpen={() => {
                        setTimeout(() => {
                          updateMathJax();
                        }, 25);
                      }}
                    >
                      <Typography
                        key={`check-${index}`}
                        display="flex"
                        align="center"
                        justifyContent="center"
                      >
                        {wrapMathString(`\\begin{align} ${check.symbolic} \\end{align}`)}
                        <ArrowForwardIcon sx={{ paddingInline: "0.5em" }} />
                        {check.resultMessage}
                      </Typography>
                    </Tooltip>
                  </Box>
                );
              case "ComparisonForced":
                const textcheck = item as ComparisonForced;
                return (
                  <Box
                    key={"calc-description-box-" + index}
                    padding="0.5rem"
                    margin="0.5rem"
                    sx={{
                      backgroundColor: theme.palette.primary.light,
                      borderColor: theme.palette.primary.main,
                      borderStyle: "solid",
                      borderRadius: "1rem",
                    }}
                  >
                    <Tooltip
                      title={textcheck.description || "Check Results"}
                      onOpen={() => {
                        setTimeout(() => {
                          updateMathJax();
                        }, 25);
                      }}
                    >
                      <Typography
                        key={`textcheck-${index}`}
                        display="flex"
                        align="center"
                        justifyContent="center"
                      >
                        {wrapMathString(textcheck.symbolic ?? "")}
                      </Typography>
                    </Tooltip>
                  </Box>
                );
              case "FigureBase":
                return <FigureDesigner item={item as FigureBase} key={"calc-figure-" + index} />;
              case "Symbolic":
                const parsedSym = item as Symbolic;
                return (
                  <Box
                    key={"calc-description-box-" + index}
                    padding="0.5rem"
                    margin="0.5rem"
                    sx={{
                      borderStyle: "solid",
                      borderRadius: "1rem",
                      borderColor: theme.palette.grey["800"],
                    }}
                  >
                    <Typography key={`calc-description-${index}`}>
                      {parsedSym.description}
                    </Typography>
                    <Typography key={`calc-result-${index}`} align="center">
                      {wrapMathString(`${parsedSym.name} ${parsedSym.symbolic}`)}
                    </Typography>
                  </Box>
                );
              case "Table":
                const parsedTable = { ...item, fullWidth: false } as CalcTable;
                return <ResultTableItem item={parsedTable} />;
              default:
                return null;
            }
          })}
          <Backdrop open={isResultStale} sx={{ position: "absolute", borderRadius: "1rem" }}>
            <Typography
              fontWeight={700}
              margin="1rem"
              padding="1rem"
              sx={{
                backgroundColor: theme.palette.grey[700],
                color: "white",
                borderRadius: "1rem",
                opacity: "90%",
              }}
            >
              Input has changed. Refresh calculation to save inputs and see updated results.
            </Typography>
          </Backdrop>
        </Box>
      )}
    </Box>
  );
}
