import { Button, Container, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { FieldValues, FormContainer, PasswordElement, TextFieldElement } from "react-hook-form-mui";
import { useNavigate } from "react-router";
import { UserLoginDto } from "../commonTypes/UserT";
import { useAppDispatch, useAppSelector } from "../hooks";
import { authActions, getAuthStatus, getUser } from "../reduxSlices/auth";
import { routes } from "../routes";
import FormPendingSkeleton from "./FormPendingSkeleton";

function HeadTags() {
  return (
    <Helmet>
      <title>Login | efficalc</title>
      <meta
        name="description"
        content="Log in to your efficalc platform account to edit, vew, and use your calculations."
      />
      <meta property="og:title" content="Login" />
      <meta property="og:url" content="https://www.efficalc.com/login" />
      <meta
        property="og:description"
        content="Log in to your efficalc platform account to edit, vew, and use your calculations."
      />
    </Helmet>
  );
}

const defaultValues = {
  email: "",
  password: "",
};

export default function LogInForm() {
  const navigate = useNavigate();
  const user = useAppSelector(getUser);

  const dispatch = useAppDispatch();
  const authStatus = useAppSelector(getAuthStatus);

  const handleSubmit = (data: FieldValues) => {
    const userLoginDto = data as UserLoginDto;
    dispatch(authActions.loginUser(userLoginDto));
  };

  useEffect(() => {
    if (user) navigate(routes.templates.path);
  }, [user, navigate]);

  return (
    <>
      <HeadTags />
      <Container maxWidth="sm" sx={{ marginBlock: "2rem" }}>
        {authStatus === "loading" ? (
          <FormPendingSkeleton />
        ) : (
          <FormContainer defaultValues={defaultValues} onSuccess={handleSubmit}>
            <Stack spacing={2}>
              <Typography variant="h3">Log In:</Typography>
              <Typography gutterBottom>
                Sign into your efficalc account to access your calculations, projects, and
                templates.
              </Typography>
              <TextFieldElement required type="email" name="email" label="Email Address" />
              <PasswordElement required name="password" label="Password" />
              <Button fullWidth type="submit" variant="contained">
                Log In
              </Button>
            </Stack>
          </FormContainer>
        )}
      </Container>
    </>
  );
}
