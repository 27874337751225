import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../hooks";
import { isUserLoggedIn } from "../../reduxSlices/auth";
import { routes } from "../../routes";
import NavBarUser from "./NavBarUser";
import NavLinks from "./NavLinks";

// adapted from https://mui.com/material-ui/react-app-bar/
export default function NavBar() {
  const isLoggedIn = useAppSelector(isUserLoggedIn);

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenIconMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseIconMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static">
      <Toolbar disableGutters>
        <Box
          sx={{
            display: { xs: "none", lg: "flex" },
            backgroundColor: "white",
            marginInlineEnd: "1rem",
            height: "3.5rem",
          }}
        >
          <Link to={routes.home.path}>
            <img src="/efficalc_platform.jpg" alt="Efficalc Logo" className="main-logo"></img>
          </Link>
        </Box>

        <Box sx={{ flexGrow: 1, display: { xs: "flex", lg: "none" } }}>
          <IconButton
            size="large"
            aria-label="site menu options"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenIconMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseIconMenu}
            sx={{
              display: { xs: "block", lg: "none" },
            }}
          >
            <NavLinks
              formatForPopupMenu={true}
              handleCloseIconMenu={handleCloseIconMenu}
              isLoggedIn={isLoggedIn}
            />
          </Menu>
        </Box>

        <Box
          sx={{
            display: { xs: "flex", lg: "none" },
            height: "3rem",
            backgroundColor: "transparent",
          }}
        >
          <Link to={routes.home.path}>
            <img src="/efficalc_platform.jpg" alt="Efficalc Logo" className="main-logo"></img>
          </Link>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: { xs: "flex", lg: "none" },
          }}
        ></Box>

        <Box sx={{ flexGrow: 1, display: { xs: "none", lg: "flex" } }}>
          <NavLinks
            formatForPopupMenu={false}
            handleCloseIconMenu={handleCloseIconMenu}
            isLoggedIn={isLoggedIn}
          />
        </Box>
        <Box sx={{ flexGrow: 0, textAlign: "right" }}>
          <NavBarUser />
        </Box>
      </Toolbar>
    </AppBar>
  );
}
