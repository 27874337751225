import { Box } from "@mui/material";
import { CalcTable } from "../commonTypes/CalculationRunTypes";
import { SimpleTable } from "./CalcReportComponents/TableReport";

interface Props {
  item: CalcTable;
}

export default function ResultTableItem({ item }: Props) {
  return (
    <>
      <Box margin={2}>
        <SimpleTable item={item} />
      </Box>
    </>
  );
}
