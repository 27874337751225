import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import ContactFormButton from "../ContactFormButton";
import ErrorManager from "../ErrorManager";
import ErrorSnackbar from "../ErrorSnackbar";
import HomeBar from "../HomeBar";
import { privacyPolicyLink, termsLink } from "../RegisterForm";

const StyledCalcLink = styled("a")({
  textDecoration: "none",
  color: "inherit",
});

const efficalcLink = () => (
  <StyledCalcLink href="https://www.efficalc.com/" target="_blank" rel="noopener noreferrer">
    efficalc
  </StyledCalcLink>
);

function HeadTags() {
  return (
    <Helmet>
      <title>Engineering Calculations Reimagined | efficalc</title>
      <meta
        name="description"
        content="A better way to create engineering calculations that are transparent, powerful, and reliable. 
      Harness the power of cloud-computing with automated calculation reports, design interface, and project managment."
      />
      <meta property="og:title" content="Engineering Calculations Reimagined" />
      <meta property="og:url" content="https://www.efficalc.com/" />
      <meta property="og:image" content="https://efficalc.com/efficalcOG.png" />
      <meta
        property="og:description"
        content="A better way to create engineering calculations that are transparent, powerful, and reliable. 
    Harness the power of cloud-computing with automated calculation reports, design interface, and project managment."
      />
    </Helmet>
  );
}

export default function GlobalBasePage() {
  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      <HeadTags />
      <HomeBar />
      <ErrorManager />
      <ErrorSnackbar />
      <Outlet />
      <ContactFormButton />
      <Box flexGrow="1"></Box>
      <div id="bottom-block" className="container-fluid text-center bottom-container">
        <footer>
          <p>Thanks for using {efficalcLink()}! Brought to you by Encomp.</p>
          <p>
            Copyright &copy; 2024. All rights reserved. {privacyPolicyLink} | {termsLink}
          </p>
        </footer>
      </div>
    </Box>
  );
}
