import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { PublicCalcName } from "../../commonTypes/CalculationT";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { errorsActions } from "../../reduxSlices/errors";
import {
  calculationActions,
  getCalculationRunResults,
  getCalculationRunStatus,
} from "../../reduxSlices/public-calc";
import CalcReport from "../CalcReport";
import ErrorManager from "../ErrorManager";
import ErrorSnackbar from "../ErrorSnackbar";
import FormPendingSkeleton from "../FormPendingSkeleton";
import { getLocalInputs, getLocalInputTables } from "./utils";

function HeadTags() {
  return (
    <Helmet>
      <title>Calculation Report | efficalc</title>
      <meta property="og:title" content="Calculation Reports" />
      <meta name="description" content="View or print your calculation report." />
      <meta property="og:description" content="View or print your calculation report." />
      <meta property="og:image" content="https://efficalc.com/efficalcOG.png" />
    </Helmet>
  );
}

export default function PublicCalcReportPage() {
  const dispatch = useAppDispatch();
  const currentRun = useAppSelector(getCalculationRunResults);
  const currentRunStatus = useAppSelector(getCalculationRunStatus);
  const calcName = useParams().name;

  useEffect(() => {
    if (calcName) {
      const inputs = getLocalInputs(calcName as PublicCalcName);
      const inputTables = getLocalInputTables(calcName as PublicCalcName);
      dispatch(
        calculationActions.runPublicCalculation({
          name: calcName as PublicCalcName,
          inputs: { ...inputs, ...inputTables },
        })
      );
    } else {
      dispatch(errorsActions.throwError("Invalid calculation identifier."));
    }
  }, [calcName]);

  return (
    <>
      <HeadTags />
      <ErrorManager />
      <ErrorSnackbar />
      {calcName !== currentRun?.name || currentRunStatus === "loading" ? (
        <FormPendingSkeleton />
      ) : (
        <CalcReport runResults={currentRun?.items || []} paperView />
      )}
    </>
  );
}
