import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { CalcTypeToParse } from "../commonTypes/CalculationRunTypes";
import CalcReport from "./CalcReport";

interface Props {
  pathToReportPage: string;
  openState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  runResults?: CalcTypeToParse[];
}

// TODO: preview calc report takes forever to render
// --> Consider more back end work i.e. BFF for calc report and design page. (one object type rather than FE switch statements)
export default function CalcReportPreviewDialog({
  pathToReportPage,
  openState,
  runResults,
}: Props) {
  const [open, setOpen] = openState;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      scroll="paper"
      aria-label="calculation-report-preview"
    >
      <DialogContent dividers={true}>
        <CalcReport runResults={runResults || []} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            window.open(
              window.location.protocol + "//" + window.location.host + pathToReportPage,
              "_blank"
            );
          }}
        >
          Print in new tab
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
