import hljs from "highlight.js/lib/core";
import python from "highlight.js/lib/languages/python";
import "highlight.js/styles/atom-one-light.css";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

export default function PublicCalcBase() {
  useEffect(() => {
    hljs.registerLanguage("python", python);
  }, []);
  return (
    <>
      <Outlet />
    </>
  );
}
