import { Button, Container, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  CheckboxElement,
  FieldValues,
  FormContainer,
  PasswordElement,
  PasswordRepeatElement,
  TextFieldElement,
} from "react-hook-form-mui";
import { useNavigate } from "react-router";
import { UserRegisterDto } from "../commonTypes/UserT";
import { useAppDispatch, useAppSelector } from "../hooks";
import { authActions, getAuthStatus, getUser } from "../reduxSlices/auth";
import { routes } from "../routes";
import FormPendingSkeleton from "./FormPendingSkeleton";

function HeadTags() {
  return (
    <Helmet>
      <title>Register | efficalc</title>
      <meta
        name="description"
        content="Register for a free efficalc platform account to edit, vew, and use your calculations."
      />
      <meta property="og:title" content="Register" />
      <meta property="og:url" content="https://www.efficalc.com/register" />
      <meta
        property="og:description"
        content="Register for a free efficalc platform account to edit, vew, and use your calculations."
      />
    </Helmet>
  );
}

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  passwordConfirm: "",
  agree: "",
};

export const privacyPolicyLink = (
  <a href="/privacy_policy.html" target="_blank" rel="noopener noreferrer">
    Privacy Policy
  </a>
);

export const termsLink = (
  <a href="/terms.html" target="_blank" rel="noopener noreferrer">
    Terms of Use
  </a>
);

const PrivacyStatement = () => (
  <span>
    I have read and agree to the {privacyPolicyLink} and {termsLink}. I also agree that in no event
    will the website owner or affiliates be held liable for any damages resulting from using the
    website. I take responsibility for confirming any calculations that this website provides.
  </span>
);

export default function RegisterForm() {
  const navigate = useNavigate();
  const user = useAppSelector(getUser);

  const dispatch = useAppDispatch();
  const authStatus = useAppSelector(getAuthStatus);

  const handleSubmit = (data: FieldValues) => {
    const registerUserDto = data as UserRegisterDto;
    dispatch(authActions.registerUser(registerUserDto));
  };

  useEffect(() => {
    if (user) navigate(routes.templates.path);
  }, [user, navigate]);

  return (
    <Container maxWidth="sm" sx={{ marginBlock: "2rem" }}>
      <HeadTags />
      {authStatus === "loading" ? (
        <FormPendingSkeleton />
      ) : (
        <FormContainer
          defaultValues={defaultValues}
          onSuccess={handleSubmit}
          reValidateMode="onBlur" // why doesnt this work?
        >
          <Stack spacing={2} id="register-check-group">
            <Typography variant="h3">Register:</Typography>
            <Typography gutterBottom>
              Create a new efficalc account for free to start writing better calculations.
            </Typography>
            <TextFieldElement
              required
              type="text"
              name="firstName"
              label="First Name"
              validation={{
                validate: (name: string) => !name.includes(".") || !name.includes("/"),
              }}
            />
            <TextFieldElement
              required
              type="text"
              name="lastName"
              label="Last Name"
              validation={{
                validate: (name: string) => !name.includes(".") || !name.includes("/"),
              }}
            />
            <TextFieldElement required type="email" name="email" label="Email Address" />
            <PasswordElement required name="password" label="Password" />
            <PasswordRepeatElement
              required
              passwordFieldName="password"
              name="passwordConfirm"
              label="Confirm Password"
            />
            <CheckboxElement required name="agree" label={PrivacyStatement()} />
            <Button fullWidth type="submit" variant="contained">
              Register
            </Button>
          </Stack>
        </FormContainer>
      )}
    </Container>
  );
}
