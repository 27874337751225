import { Box } from "@mui/material";
import { Canvas } from "../commonTypes/CalculationRunTypes";
import { CalcTypography } from "./CalcReportComponents/reportUtilities";

type Props = {
  item: Canvas;
};

export default function CanvasDesigner({ item }: Props) {
  return item.svg ? (
    <Box margin={2} maxWidth="100%">
      <div dangerouslySetInnerHTML={{ __html: item.svg }} />
      {item.caption && (
        <CalcTypography
          textAlign={item.centered ? "center" : undefined}
          variant="caption"
          color="text.secondary"
          variantMapping={{ caption: "p" }}
        >
          {item.caption}
        </CalcTypography>
      )}
    </Box>
  ) : (
    <></>
  );
}
