import React, { useMemo } from "react";
import Carousel from "react-material-ui-carousel";
import { Paper, Box, Breakpoint, useMediaQuery, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DemoVideo from "./DemoVideo";

export const WIDTHS: Record<Breakpoint, number> = {
  xl: 896,
  lg: 896,
  md: 672,
  xs: 448,
  sm: 560,
};
export const HEIGHTS: Record<Breakpoint, number> = {
  lg: 504,
  xl: 504,
  md: 378,
  xs: 252,
  sm: 315,
};

// const CarouselIndicatorText = styled(Typography)

const CarouselIndicators = [
  <Typography fontWeight="bold">First Steps</Typography>,
  <Typography fontWeight="bold">Calc'd Variables</Typography>,
  <Typography fontWeight="bold">Design Checks</Typography>,
  <Typography fontWeight="bold">Text Elements</Typography>,
];

const CarouselIndicatorsAlt = [
  <Typography fontWeight="bold">
    First
    <br />
    Steps
  </Typography>,
  <Typography fontWeight="bold">
    Calc'd
    <br />
    Variables
  </Typography>,
  <Typography fontWeight="bold">
    Design
    <br />
    Checks
  </Typography>,
  <Typography fontWeight="bold">
    Text
    <br />
    Elements
  </Typography>,
];

export default function DemoCarousel() {
  const theme = useTheme();
  const xlBreakPoint = useMediaQuery(theme.breakpoints.up("xl"));
  const lgBreakPoint = useMediaQuery(theme.breakpoints.up("lg"));
  const mdBreakPoint = useMediaQuery(theme.breakpoints.up("md"));
  const xsBreakPoint = useMediaQuery(theme.breakpoints.down("sm"));

  const indicators = useMemo(
    () => (xsBreakPoint ? CarouselIndicatorsAlt : CarouselIndicators),
    [xsBreakPoint]
  );

  const width = xlBreakPoint
    ? WIDTHS.xl
    : lgBreakPoint
    ? WIDTHS.lg
    : mdBreakPoint
    ? WIDTHS.md
    : xsBreakPoint
    ? WIDTHS.xs
    : WIDTHS.sm;

  const height = xlBreakPoint
    ? HEIGHTS.xl
    : lgBreakPoint
    ? HEIGHTS.lg
    : mdBreakPoint
    ? HEIGHTS.md
    : xsBreakPoint
    ? HEIGHTS.xs
    : HEIGHTS.sm;

  return (
    <Box width="100%" display="flex" alignItems="center" justifyContent="center">
      <Paper
        elevation={6}
        sx={{
          display: "inline-block",
          overflow: "hidden",
          width: width,
          background: theme.palette.primary.main,
        }}
      >
        <Carousel
          autoPlay={false}
          animation="slide"
          IndicatorIcon={indicators}
          indicatorIconButtonProps={{
            style: {
              paddingLeft: mdBreakPoint ? "20px" : "12px",
              paddingRight: mdBreakPoint ? "20px" : "12px",
              color: theme.palette.secondary.light,
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              color: theme.palette.primary.light,
            },
          }}
          indicatorContainerProps={{
            style: {
              marginBottom: "20px",
            },
          }}
        >
          <DemoVideo embedCode="7Yx_xCc3mi8" width={width} height={height} />
          <DemoVideo embedCode="95tAUPO_xRQ" width={width} height={height} />
          <DemoVideo embedCode="iljY7sKDsUQ" width={width} height={height} />
          <DemoVideo embedCode="bI1-OjdeDQk" width={width} height={height} />
        </Carousel>
      </Paper>
    </Box>
  );
}
