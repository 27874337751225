import { Button } from "@mui/material";
import { GLOBAL_THEME } from "../../App";

const buttonMargin = (isPopupMenu?: boolean) => (isPopupMenu ? "0" : "0.5rem");
const buttonTextAlign = (isPopupMenu?: boolean) => (isPopupMenu ? "left" : "center");
const buttonColor = (isActive: boolean, isPopupMenu?: boolean) =>
  isActive
    ? GLOBAL_THEME.palette.secondary.main
    : isPopupMenu
    ? GLOBAL_THEME.palette.primary.main
    : "white";

type Props = {
  isActive: boolean;
  navName: string;
  handleCloseIconMenu: () => void;
  dropMenu?: boolean;
};

export const NavButton = ({ isActive, navName, handleCloseIconMenu, dropMenu }: Props) => {
  return (
    <Button
      onClick={handleCloseIconMenu}
      fullWidth={dropMenu}
      sx={{
        my: 2,
        display: "block",
        fontWeight: "bold",
        color: buttonColor(isActive, dropMenu),
        margin: buttonMargin(dropMenu),
        textAlign: buttonTextAlign(dropMenu),
      }}
    >
      {navName}
    </Button>
  );
};
