import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import { Box, Container, IconButton, Skeleton, Tooltip, Typography } from "@mui/material";
import hljs from "highlight.js/lib/core";
import { useEffect, useRef, useState } from "react";
import { PublicCalcName } from "../../../commonTypes/CalculationT";
import { useGetPublicCalculationContentsQuery } from "../../../reduxSlices/public-calc";
import { Code } from "./copy-utils";

const LoadingSkeleton = () => <Skeleton variant="rounded" height={"18rem"} />;

type Props = {
  calcName: PublicCalcName;
};

export default function CodeContents({ calcName }: Props) {
  const [copySuccess, setCopySuccess] = useState<boolean>();
  const successTimeout = useRef<NodeJS.Timeout>();

  const { data, error, isLoading } = useGetPublicCalculationContentsQuery(calcName);

  const content = data?.contents;

  useEffect(() => {
    hljs.highlightAll();
  }, [content]);

  function copyContent() {
    navigator.clipboard
      .writeText(content || "")
      .then(() => setCopySuccess(true))
      .catch(() => setCopySuccess(false))
      .finally(() => {
        clearTimeout(successTimeout.current);
        successTimeout.current = setTimeout(() => setCopySuccess(undefined), 3000);
      });
  }

  const CopySuccessMessage = () =>
    copySuccess ? (
      <Typography
        variant="body1"
        color={(theme) => theme.palette.success.dark}
        display="flex"
        marginY={1}
      >
        <CheckCircleIcon /> Copied
      </Typography>
    ) : (
      <Typography
        variant="body1"
        color={(theme) => theme.palette.error.dark}
        marginY={1}
        maxWidth={300}
      >
        Failed to copy content. Your browser settings may have prevented this, but you can still
        manually copy from the text below.
      </Typography>
    );

  return (
    <Container maxWidth="sm" sx={{ position: "relative", marginBlock: "2rem" }}>
      {isLoading ? (
        <LoadingSkeleton />
      ) : error && !data ? (
        <></>
      ) : (
        <>
          <Typography
            sx={{
              position: "absolute",
              top: "0",
              right: "2.5rem",
            }}
            variant="body1"
          >
            {copySuccess !== undefined ? (
              <CopySuccessMessage />
            ) : (
              <Tooltip title="Copy to clipboard" placement="left">
                <IconButton onClick={copyContent} color="primary">
                  <ContentCopyTwoToneIcon />
                </IconButton>
              </Tooltip>
            )}
          </Typography>
          <Box
            sx={{
              height: "18rem",
              overflow: "auto",
              backgroundColor: (theme) => theme.palette.grey[100],
              fontSize: "0.55em",
              padding: "1em",
              borderRadius: 2,
              lineHeight: "1.4em",
            }}
          >
            <pre>
              <Code className="language-python">{content}</Code>
            </pre>
          </Box>
        </>
      )}
    </Container>
  );
}
