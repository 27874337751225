import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";
import { PublicCalcName } from "../../../commonTypes/CalculationT";

type CalcToolMetadata = {
  title: string;
  imagePath: string;
  link: string;
};

export type RelatedToolType = PublicCalcName | "truss-tool" | "concrete-beam-tool";

const calcToolToMetadata: Record<RelatedToolType, CalcToolMetadata> = {
  "steel-beam-calculator": {
    title: "Steel Beam Capacity",
    imagePath: "/images/public-calcs/wf_beam_design_bkg.jpg",
    link: "https://www.efficalc.com/public-calc/design/steel-beam-calculator",
  },
  "steel-beam-optimizer": {
    title: "Efficient Steel Beam Design",
    imagePath: "/images/public-calcs/wf_beam_optimizer_bkg.jpg",
    link: "https://www.efficalc.com/public-calc/design/steel-beam-optimizer",
  },
  "concrete-column-pmm": {
    title: "Concrete Column PMM Designer",
    imagePath: "/images/public-calcs/conc_col_pmm_bkg.jpg",
    link: "https://www.efficalc.com/public-calc/design/concrete-column-pmm",
  },
  "truss-tool": {
    title: "Truss Analysis Tool",
    imagePath: "/images/public-calcs/truss_og_image.png",
    link: "https://trussanalysis.com/",
  },
  "concrete-beam-tool": {
    title: "Concrete Beam Design Tool",
    imagePath: "/images/public-calcs/concrete_og.png",
    link: "https://concrete.encompapp.com/",
  },
};

type Props = {
  calcName: RelatedToolType;
  text: string;
  titleOverride?: string;
};

// , ":hover": { backgroundColor: (theme) => theme.palette.grey[100] }

export default function RelatedCalculationTool({ calcName, text, titleOverride }: Props) {
  const metadata = calcToolToMetadata[calcName];

  const onClick = () => {
    window.open(metadata.link, "_blank", "noopener,noreferrer");
  };

  return (
    <Card sx={{ maxWidth: 350 }} elevation={6}>
      <CardActionArea onClick={onClick}>
        <CardMedia sx={{ height: 150 }} image={metadata.imagePath} title={metadata.title} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {titleOverride || metadata.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {text}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
