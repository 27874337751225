import NoteAddIcon from "@mui/icons-material/NoteAdd";
import PreviewIcon from "@mui/icons-material/Preview";
import RefreshIcon from "@mui/icons-material/Refresh";
import { CircularProgress, IconButton, Stack, Tooltip } from "@mui/material";
import { useState } from "react";
import { CalcTypeToParse } from "../commonTypes/CalculationRunTypes";
import CalcReportPreviewDialog from "./CalcReportPreviewDialog";

interface Props {
  pathToReportPage: string;
  updateButtonText: string;
  updateResults: () => void;
  isResultStale: boolean;
  isRunLoading: boolean;
  runResults?: CalcTypeToParse[];
}

export default function ResultActionButtons({
  pathToReportPage,
  updateButtonText,
  updateResults,
  isResultStale,
  isRunLoading,
  runResults,
}: Props) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClickPreview = () => {
    setDialogOpen(true);
  };

  const handleClickOpen = () => {
    window.open(
      window.location.protocol + "//" + window.location.host + pathToReportPage,
      "_blank"
    );
  };

  return (
    <>
      <Stack direction="row">
        {isRunLoading ? (
          <Tooltip title="Loading updated results..." placement="top">
            <CircularProgress size="1.5em" sx={{ padding: "0.5em" }} />
          </Tooltip>
        ) : (
          <Tooltip title={updateButtonText} placement="top">
            <IconButton onClick={updateResults} color="primary">
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="Preview calculation report." placement="top">
          <IconButton onClick={handleClickPreview} disabled={isResultStale} color="primary">
            <PreviewIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Open or print calculation report in new window." placement="top">
          <IconButton onClick={handleClickOpen} disabled={isResultStale} color="primary">
            <NoteAddIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      <CalcReportPreviewDialog
        pathToReportPage={pathToReportPage}
        openState={[dialogOpen, setDialogOpen]}
        runResults={runResults}
      />
    </>
  );
}
