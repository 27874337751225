import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  calculationActions,
  getCalculationRunResults,
  getCurrentCalculation,
} from "../../reduxSlices/calculation";
import { errorsActions } from "../../reduxSlices/errors";
import CalcReport from "../CalcReport";
import ErrorManager from "../ErrorManager";
import ErrorSnackbar from "../ErrorSnackbar";
import FormPendingSkeleton from "../FormPendingSkeleton";

function HeadTags() {
  return (
    <Helmet>
      <title>Calculation Report | efficalc</title>
      <meta property="og:title" content="Calculation Reports" />
      <meta name="description" content="View or print your calculation report." />
      <meta property="og:description" content="View or print your calculation report." />
      <meta property="og:image" content="https://efficalc.com/efficalcOG.png" />
    </Helmet>
  );
}

// TODO: Improve loading skeleton, include text to say loading/preparing calc report or something
export default function CalculationReportPage() {
  const dispatch = useAppDispatch();
  const currentCalculationInfo = useAppSelector(getCurrentCalculation);
  const currentRun = useAppSelector(getCalculationRunResults);
  const calcId = +(useParams().id ?? NaN);

  useEffect(() => {
    if (!!calcId) {
      if (calcId !== currentCalculationInfo?.id)
        dispatch(calculationActions.fetchCalculation(calcId));

      dispatch(calculationActions.getCalculationItems(calcId));
    } else {
      dispatch(errorsActions.throwError("Invalid calculation identifier."));
    }
  }, [calcId]);

  return (
    <>
      <HeadTags />
      <ErrorManager />
      <ErrorSnackbar />
      {calcId !== currentRun?.id ? (
        <FormPendingSkeleton />
      ) : (
        <CalcReport runResults={currentRun?.items || []} paperView />
      )}
    </>
  );
}
