import { Stack } from "@mui/material";
import Container from "@mui/material/Container";
import { Helmet } from "react-helmet";
import { PublicCalcName } from "../../commonTypes/CalculationT";
import CloneOrCopyCode from "./common/CloneOrCopyCode";
import DesignSection from "./common/DesignSection";
import IntroSection from "./common/IntroSection";
import RelatedCalcToolSection, { RelatedTool } from "./common/RelatedCalcToolSection";
import { Disclaimer, Image, P, Title } from "./common/copy-utils";

const CALC_TITLE = "Concrete Column PMM Designer";
export const CALC_NAME: PublicCalcName = "concrete-column-pmm";

// TODO: update head info
function HeadTags() {
  return (
    <Helmet>
      <title>{CALC_TITLE} | efficalc</title>
      <meta
        name="description"
        content="Easily design your concrete column with our free online PMM designer. Quickly and accurately get load case PM diagrams and a detailed calculation report. Try it now for free!"
      />
      <meta property="og:title" content={`${CALC_TITLE} | efficalc`} />
      <meta
        property="og:url"
        content="https://efficalc.com/public-calc/design/concrete-column-pmm"
      />
      <meta
        property="og:image"
        content="https://efficalc.com/images/public-calcs/conc_col_pmm_bkg.jpg"
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:description"
        content="A free online design tool to quickly and accurately design a concrete column subjected to biaxial bending."
      />
    </Helmet>
  );
}

// TODO: update description info
function Introduction() {
  const Description = (
    <>
      <P>
        Easily design concrete columns with this advanced PMM tool from efficalc. Define your column
        geometry and load cases, and the tool will generate a detailed 3D PMM plot along with a
        comprehensive report featuring 2D P-M plots for each load case. Instantly verify the
        column's capacity by comparing each load case against the column's P-M capacity in that
        direction of loading.
      </P>
      <Image
        src="/images/public-calcs/conc_col_pmm.jpg"
        alt="Concrete column in biaxial bending with P-M diagram"
      ></Image>
    </>
  );

  const instantResultsText =
    "Simply enter the column properties and load combinations to receive an immediate and accurate design strength evaluation with 3d PMM plot.";

  return (
    <IntroSection
      title={CALC_TITLE}
      descriptionSection={Description}
      instantResultsText={instantResultsText}
    />
  );
}

function DesignIntro() {
  return (
    <Container maxWidth="md">
      <Title variant="h2" gutterBottom>
        Design your column here:
      </Title>
      <P>
        Use the interactive form below to design your column for bi-directional flexure. View or
        print the calculation report using the icons in the "Results" section.
      </P>
    </Container>
  );
}

const relatedTools: RelatedTool[] = [
  {
    calcName: "steel-beam-calculator",
    text: "Quickly and accurately design steel wide flange beam for your given moment demand and beam span.",
  },
  {
    calcName: "concrete-beam-tool",
    text: "Calculate the capacity of a doubly or singly reinforced concrete beam.",
  },
];

export default function ConcreteColumnPmm() {
  return (
    <Container maxWidth="xl">
      <HeadTags />

      <Stack marginY="1rem">
        <Introduction />

        <DesignIntro />
        <DesignSection calcName={CALC_NAME} />
        <CloneOrCopyCode calcName={CALC_NAME} contentUnavailable />
        <RelatedCalcToolSection relatedTools={relatedTools} />
      </Stack>

      <Disclaimer />
    </Container>
  );
}
