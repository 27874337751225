import { Box, Container } from "@mui/material";
import ContactFormButton from "../../ContactFormButton";
import { LI, P, Subtitle, Title } from "./copy-utils";

type Props = {
  title: string;
  descriptionSection: JSX.Element;
  instantResultsText: string;
};

export default function IntroSection({ title, descriptionSection, instantResultsText }: Props) {
  return (
    <Container maxWidth="md">
      <Title variant="h1" gutterBottom>
        {title}
      </Title>
      {descriptionSection}
      <Subtitle variant="h2">What you get:</Subtitle>
      <Box sx={{ marginBlock: "1em" }}>
        <ul>
          <LI>
            <b>Instant Results:</b> {instantResultsText}
          </LI>
          <LI>
            <b>Detailed Calculation Report:</b> Your calculation comes with a free
            professional-quality calculation report which details every calculation step, making it
            simple to review, verify, and submit in a design package.
          </LI>
          <LI>
            <b>Customization:</b> You can change any part of these calculations and make it your
            own! Simply copy or save this template below and edit it in any way that suits your
            project.
          </LI>
          <LI>
            <b>Open Source:</b> This calculator is 100% built with our open-source Python
            calculations library, efficalc. You can make your own calculations just like this on the
            website, or in a local Python environment and integrate them with your other Python
            workflows.
          </LI>
        </ul>
      </Box>

      <P>
        Designed with modern engineers in mind, efficalc will not only speed up your design process
        but also integrate seamlessly with today's automated and data-driven engineering
        environments.
      </P>
      <P>
        We value your feedback and are always looking for ways to enhance our tools. Reach out to us
        with the contact button (<ContactFormButton inline />) to the right or at{" "}
        <a
          href="https://github.com/youandvern/efficalc/issues"
          target="_blank"
          rel="noopener noreferrer"
        >
          github.com/youandvern/efficalc/issues
        </a>{" "}
        anytime!
      </P>
    </Container>
  );
}
