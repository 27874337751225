import { PublicCalcName } from "../../commonTypes/CalculationT";
import { FormValuesT, TableFormValuesT } from "../CalculationInputTable";

export const saveLocalInputs = (calcName: PublicCalcName, inputs: FormValuesT): void => {
  localStorage.setItem(calcName, JSON.stringify(inputs));
};

export const getLocalInputs = (calcName: PublicCalcName): FormValuesT => {
  const savedItems = localStorage.getItem(calcName);

  if (savedItems) {
    return JSON.parse(savedItems) as FormValuesT;
  } else {
    return {};
  }
};

const inputTableKey = (calcName: string) => `${calcName}-input-table`;

export const saveLocalInputTables = (
  calcName: PublicCalcName,
  inputTables: TableFormValuesT
): void => {
  localStorage.setItem(inputTableKey(calcName), JSON.stringify(inputTables));
};

export const getLocalInputTables = (calcName: PublicCalcName): TableFormValuesT => {
  const savedItems = localStorage.getItem(inputTableKey(calcName));

  if (savedItems) {
    return JSON.parse(savedItems) as TableFormValuesT;
  } else {
    return {};
  }
};
