import { Stack, Typography } from "@mui/material";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  getCurrentTemplateId,
  getTemplateCreateStatus,
  getTemplates,
  templatesActions,
} from "../../reduxSlices/template";
import FormDialog from "../FormDialog";
import CreateTemplateForm from "../Forms/CreateTemplateForm";
import Template from "../Template";
import { routes } from "../../routes";
import { useNavigate } from "react-router-dom";

const getStartedMessage = () => (
  <Typography>
    You don't have any templates yet. To get started, create a template:{" "}
    <FormDialog
      FormComponent={CreateTemplateForm}
      ButtonComponent={<AddCircleOutlineRoundedIcon fontSize="inherit" color="success" />}
    />
  </Typography>
);

export default function TemplateUploadPage() {
  const templates = useAppSelector(getTemplates);
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const templateCreateStatus = useAppSelector(getTemplateCreateStatus);
  const currentTemplateId = useAppSelector(getCurrentTemplateId);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    document.title = "My Templates | efficalc";
  }, []);

  useEffect(() => {
    if (templateCreateStatus === "loading") setSubmitted(true);
    if (templateCreateStatus === "failed") setSubmitted(false);
  }, [templateCreateStatus]);

  // take use directly to the template editor when they create a new template
  useEffect(() => {
    if (submitted === true && templateCreateStatus === "idle" && currentTemplateId) {
      navigate(routes.templateContent.path(currentTemplateId));
    }
  }, [currentTemplateId, submitted, templateCreateStatus]);

  useEffect(() => {
    dispatch(templatesActions.fetchTemplates());
  }, []);

  return (
    <>
      <Stack spacing={2} alignItems="center" marginTop="2rem">
        <Stack direction="row">
          <Typography variant="h2">Templates: </Typography>
          <FormDialog
            FormComponent={CreateTemplateForm}
            ButtonComponent={<AddCircleOutlineRoundedIcon fontSize="large" color="success" />}
          />
        </Stack>
        {(templates == null || templates.length === 0) && getStartedMessage()}
        {templates.map((template) => (
          <Template key={"template-" + template.id} template={template} />
        ))}
      </Stack>
    </>
  );
}
