import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Container, Skeleton } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Assumption } from "../../../commonTypes/CalculationRunTypes";
import { useAppSelector } from "../../../hooks";
import {
  getCalculationRunResults,
  getCalculationRunStatus,
} from "../../../reduxSlices/public-calc";

export default function Assumptions() {
  const currentRun = useAppSelector(getCalculationRunResults);
  const currentRunStatus = useAppSelector(getCalculationRunStatus);

  const assumptions =
    currentRun?.items
      .filter((item) => item.type === "Assumption")
      .map((item) => item as Assumption) ?? [];

  return (
    <Container style={{ marginBottom: "2rem", marginTop: "1rem" }} maxWidth="md">
      {currentRunStatus === "loading" ? (
        <Skeleton variant="rounded" height="2rem" />
      ) : assumptions.length > 0 ? (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <b>Assumptions</b>
          </AccordionSummary>
          <AccordionDetails>
            <ol>
              {assumptions.map((a, i) => (
                <li key={`assumption-${i}`}>{a.value}</li>
              ))}
            </ol>
          </AccordionDetails>
        </Accordion>
      ) : (
        <></>
      )}
    </Container>
  );
}
