import Editor from "@monaco-editor/react";
import HelpIcon from "@mui/icons-material/Help";
import PrintIcon from "@mui/icons-material/Print";
import { Box, Button, FormControlLabel, Paper, Stack, Switch, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { Container } from "@mui/system";
import monaco from "monaco-editor";
import React, { KeyboardEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiFetchTemplateContent } from "../../api";
import { TemplateContentDto } from "../../commonTypes/TemplateT";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { errorsActions } from "../../reduxSlices/errors";
import {
  getCurrentTemplate,
  getTemplateError,
  getTemplateRunResults,
  templatesActions,
} from "../../reduxSlices/template";
import { routes } from "../../routes";
import CalcReport from "../CalcReport";
import DraggableDivider from "../DraggableDivider";

const LOADING_TEXT = "Loading....";

const ErrorDiv = styled("div")(({ theme }) => ({
  marginBlock: "1rem",
  backgroundColor: theme.palette.error.light,
  padding: "0.5rem",
  maxWidth: theme.breakpoints.values.lg,
  borderRadius: theme.shape.borderRadius,
}));

// sx={{ height: "90vh", overflow: "auto", marginLeft: "0.5rem", maxWidth: "40vw" }}
const ReportPaper = styled(Paper)(({ theme }) => ({
  height: "90vh",
  overflow: "auto",
  marginLeft: "0.5rem",
  maxWidth: "800px",
  [theme.breakpoints.down("lg")]: {
    maxWidth: "80vw",
  },
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100vw",
  },
}));

const IconLink = styled("a")({
  textDecoration: "none",
  height: "1em",
  color: "inherit",
});

// ref: https://holycoders.com/snippets/react-js-detect-save-copy-keyboard-shortcuts/
const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>, action: () => void) => {
  if ((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === "s") {
    event.preventDefault();
    action();
  }
};

export default function TemplateContentPage() {
  const currentTemplate = useAppSelector(getCurrentTemplate);
  const currentTemplateRun = useAppSelector(getTemplateRunResults);
  const currentError = useAppSelector(getTemplateError);
  const dispatch = useAppDispatch();
  const templateId = Number(useParams().id);

  const [content, setContent] = useState<TemplateContentDto>({
    id: templateId || 0,
    content: "",
  });
  const [displayContent, setDisplay] = useState(LOADING_TEXT);
  const [darkMode, setDarkMode] = useState(true);
  const [previewCalc, setPreviewCalc] = useState(true);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDarkMode(event.target.checked);
  };

  const handleSwitchChangePreview = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPreviewCalc(event.target.checked);
  };

  const handleContentChange = (
    value: string | undefined,
    ev: monaco.editor.IModelContentChangedEvent
  ) => {
    const contentUpdate = value == null ? displayContent : value;
    const realContent = !!contentUpdate && contentUpdate !== LOADING_TEXT;
    if (realContent) {
      setContent({ ...content, content: contentUpdate });
    }
    setDisplay(contentUpdate);
  };

  const updateContent = () => {
    dispatch(templatesActions.updateAndRunContent(content));
  };

  useEffect(() => {
    document.title = "Template Editor | efficalc";
  }, []);

  // Fetch all required data if not already in store i.e. direct page load
  useEffect(() => {
    if (templateId !== currentTemplate?.id && templateId) {
      dispatch(templatesActions.fetchTemplates());
      dispatch(templatesActions.setCurrentTemplate(templateId));
    }
  }, [templateId]);

  // fetch and set content
  useEffect(() => {
    if (templateId) {
      setDisplay(LOADING_TEXT);
      apiFetchTemplateContent(templateId)
        .then((cont) => {
          setContent({ id: templateId, content: cont });
          setDisplay(cont);
        })
        .catch((err) => dispatch(errorsActions.throwError(err)));
      dispatch(templatesActions.runTemplate(templateId));
    }
  }, [templateId]);

  const TemplateEditor = (
    <Editor
      height="90vh"
      defaultLanguage="python"
      defaultValue={displayContent}
      onChange={handleContentChange}
      theme={darkMode ? "vs-dark" : "light"}
    />
  );

  return (
    <div onKeyDown={(e) => handleKeyDown(e, updateContent)} className="">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          spacing={{ sm: 0, md: 3, lg: 6 }}
          padding="0.5rem"
          marginTop="1rem"
          sx={{ flexWrap: "wrap" }}
        >
          <Typography variant="h3" display="inline-block">
            Template: {currentTemplate ? currentTemplate.name : "not selected"}
          </Typography>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={darkMode}
                  onChange={handleSwitchChange}
                  inputProps={{ "aria-label": "dark-mode-switch" }}
                />
              }
              label="Dark Mode"
            />

            <FormControlLabel
              control={
                <Switch
                  checked={previewCalc}
                  onChange={handleSwitchChangePreview}
                  inputProps={{ "aria-label": "preview-calculation-switch" }}
                />
              }
              label="Preview Calculation"
            />

            <Button type="button" variant="contained" onClick={updateContent}>
              Save Changes
            </Button>
            <IconButton
              title="Print this report"
              color="primary"
              sx={{ marginInlineStart: "0.5rem" }}
              onClick={() =>
                window.open(
                  window.location.protocol +
                    "//" +
                    window.location.host +
                    routes.templateReport.path(templateId),
                  "_blank"
                )
              }
            >
              <PrintIcon />
            </IconButton>
            <IconButton title="Help/User Guide">
              <IconLink
                href="https://docs.encompapp.com/html/index.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <HelpIcon />
              </IconLink>
            </IconButton>
          </Box>
        </Stack>

        {currentError && (
          <ErrorDiv>
            <Typography variant="h4" color={(theme) => theme.palette.error.dark}>
              Current Error:
            </Typography>
            <Typography>{currentError}</Typography>
          </ErrorDiv>
        )}
      </Container>

      <Container maxWidth={false}>
        {previewCalc ? (
          <DraggableDivider
            leftChild={displayContent === LOADING_TEXT ? <h3>{LOADING_TEXT}</h3> : TemplateEditor}
            rightChild={
              <ReportPaper>
                <CalcReport runResults={currentTemplateRun?.items || []} />
              </ReportPaper>
            }
          />
        ) : (
          TemplateEditor
        )}
      </Container>

      <Box height="20px" />
    </div>
  );
}
