import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import PersonIcon from "@mui/icons-material/Person";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { Box, Button, Card, Container, Stack, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import { routes } from "../../routes";
import ContactFormButton from "../ContactFormButton";
import DemoCarousel from "../DemoCarousel";
import RelatedCalculationTool from "../public-calcs/common/RelatedCalculationTool";

const StyledHref = styled("a")(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.primary.main,
  fontWeight: "bold",
}));

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.primary.main,
  fontWeight: "bold",
}));

const DemoRow = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginBlock: "6rem",
  [theme.breakpoints.down("sm")]: {
    flexWrap: "wrap",
  },
}));

const DemoImage = styled("img")(({ theme }) => ({
  objectFit: "contain",
  maxWidth: "60%",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "95%",
  },
}));

const DemoText = styled(Typography)(({ theme }) => ({
  fontSize: "2.25rem",
  fontWeight: "bold",
  padding: "0.5rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "1.6rem",
  },
}));

const PageIcon = styled("img")(({ theme }) => ({
  width: "3rem",
  marginRight: "0.5rem",
}));

const IconBullet = styled(Typography)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginLeft: "2rem",
  marginBlock: "1.5rem",
  fontWeight: "normal",
}));

const CardIcon = styled("img")(({ theme }) => ({
  width: "5rem",
  marginBlockEnd: "1rem",
}));

const IconCard = styled(Card)(({ theme }) => ({
  width: "15rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "0.5rem",
  borderRadius: "0.75rem",
  borderWidth: "1px",
  border: "solid",
  borderColor: theme.palette.grey[600],
  "&:hover": {
    borderColor: theme.palette.primary.main,
    transform: "translate(0, -0.35rem)",
  },
}));

const DocumentationHref = () => (
  <StyledHref href={routes.documentation.path} target="_blank" rel="noopener noreferrer">
    Documentation
  </StyledHref>
);

interface FullPageBoxProps {
  fillColor?: "primary" | "secondary" | "grey";
}

const ColoredBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "fillColor",
})<FullPageBoxProps>(({ theme, fillColor }) => ({
  backgroundColor:
    fillColor === "primary"
      ? theme.palette.primary.light
      : fillColor === "secondary"
      ? theme.palette.secondary.light
      : theme.palette.grey[200],
  display: "flex",
  justifyContent: "center",
}));

const HomePageContainer = styled(Box)(({ theme }) => ({
  width: "100%",
  minHeight: "70vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "3rem",
  paddingBottom: "20vh",
  maxHeight: "800px",
  marginBottom: "-60px",
  [theme.breakpoints.down("md")]: {
    padding: "1rem",
    paddingBottom: "20vh",
  },
}));

const BigHeader = styled(Typography)(({ theme }) => ({
  fontSize: "3rem",
  [theme.breakpoints.down("md")]: {
    fontSize: "2rem",
  },
}));

const WavyBottom = ({ smallScreen }: { smallScreen?: boolean }) => (
  <>
    <div className="multi-wave-bottom filled-wave-container">
      <svg
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 960 90"
        preserveAspectRatio="none"
        style={{ height: smallScreen ? "90px" : "150px" }}
      >
        <path
          d="M0 18L14.5 15.8C29 13.7 58 9.3 87.2 17.2C116.3 25 145.7 45 174.8 46.5C204 48 233 31 262 27.5C291 24 320 34 349 38.7C378 43.3 407 42.7 436.2 43.7C465.3 44.7 494.7 47.3 523.8 42.7C553 38 582 26 611 20.8C640 15.7 669 17.3 698 15.7C727 14 756 9 785.2 7.7C814.3 6.3 843.7 8.7 872.8 11.8C902 15 931 19 945.5 21L960 23L960 91L945.5 91C931 91 902 91 872.8 91C843.7 91 814.3 91 785.2 91C756 91 727 91 698 91C669 91 640 91 611 91C582 91 553 91 523.8 91C494.7 91 465.3 91 436.2 91C407 91 378 91 349 91C320 91 291 91 262 91C233 91 204 91 174.8 91C145.7 91 116.3 91 87.2 91C58 91 29 91 14.5 91L0 91Z"
          opacity="0.2"
          className="shape-fill"
          style={{ transition: "all 0.4s cubic-bezier(0.16, 1, 0.3, 1) 0s" }}
        ></path>
        <path
          d="M0 41L14.5 40.7C29 40.3 58 39.7 87.2 38C116.3 36.3 145.7 33.7 174.8 32.2C204 30.7 233 30.3 262 31.7C291 33 320 36 349 36.7C378 37.3 407 35.7 436.2 39.8C465.3 44 494.7 54 523.8 60.2C553 66.3 582 68.7 611 63.5C640 58.3 669 45.7 698 39.5C727 33.3 756 33.7 785.2 36.2C814.3 38.7 843.7 43.3 872.8 44.3C902 45.3 931 42.7 945.5 41.3L960 40L960 91L945.5 91C931 91 902 91 872.8 91C843.7 91 814.3 91 785.2 91C756 91 727 91 698 91C669 91 640 91 611 91C582 91 553 91 523.8 91C494.7 91 465.3 91 436.2 91C407 91 378 91 349 91C320 91 291 91 262 91C233 91 204 91 174.8 91C145.7 91 116.3 91 87.2 91C58 91 29 91 14.5 91L0 91Z"
          opacity="0.4"
          className="shape-fill"
          style={{ transition: "all 0.4s cubic-bezier(0.16, 1, 0.3, 1) 0s" }}
        ></path>
        <path
          d="M0 65L14.5 59.5C29 54 58 43 87.2 41.7C116.3 40.3 145.7 48.7 174.8 54.5C204 60.3 233 63.7 262 64.2C291 64.7 320 62.3 349 57C378 51.7 407 43.3 436.2 42.2C465.3 41 494.7 47 523.8 47C553 47 582 41 611 37.8C640 34.7 669 34.3 698 35.3C727 36.3 756 38.7 785.2 43.8C814.3 49 843.7 57 872.8 62.3C902 67.7 931 70.3 945.5 71.7L960 73L960 91L945.5 91C931 91 902 91 872.8 91C843.7 91 814.3 91 785.2 91C756 91 727 91 698 91C669 91 640 91 611 91C582 91 553 91 523.8 91C494.7 91 465.3 91 436.2 91C407 91 378 91 349 91C320 91 291 91 262 91C233 91 204 91 174.8 91C145.7 91 116.3 91 87.2 91C58 91 29 91 14.5 91L0 91Z"
          opacity="0.6"
          className="shape-fill"
          style={{ transition: "all 0.4s cubic-bezier(0.16, 1, 0.3, 1) 0s" }}
        ></path>
        <path
          d="M0 46L14.5 48.5C29 51 58 56 87.2 60C116.3 64 145.7 67 174.8 69.5C204 72 233 74 262 75.3C291 76.7 320 77.3 349 74.3C378 71.3 407 64.7 436.2 61.3C465.3 58 494.7 58 523.8 58.5C553 59 582 60 611 63.3C640 66.7 669 72.3 698 74C727 75.7 756 73.3 785.2 71.5C814.3 69.7 843.7 68.3 872.8 65C902 61.7 931 56.3 945.5 53.7L960 51L960 91L945.5 91C931 91 902 91 872.8 91C843.7 91 814.3 91 785.2 91C756 91 727 91 698 91C669 91 640 91 611 91C582 91 553 91 523.8 91C494.7 91 465.3 91 436.2 91C407 91 378 91 349 91C320 91 291 91 262 91C233 91 204 91 174.8 91C145.7 91 116.3 91 87.2 91C58 91 29 91 14.5 91L0 91Z"
          className="shape-fill"
          style={{ transition: "all 0.4s cubic-bezier(0.16, 1, 0.3, 1) 0s" }}
        ></path>
        <path
          d="M0 75L14.5 75.8C29 76.7 58 78.3 87.2 79.8C116.3 81.3 145.7 82.7 174.8 83.3C204 84 233 84 262 83.5C291 83 320 82 349 81.5C378 81 407 81 436.2 80.3C465.3 79.7 494.7 78.3 523.8 79C553 79.7 582 82.3 611 80.2C640 78 669 71 698 69.2C727 67.3 756 70.7 785.2 72.5C814.3 74.3 843.7 74.7 872.8 74.8C902 75 931 75 945.5 75L960 75L960 91L945.5 91C931 91 902 91 872.8 91C843.7 91 814.3 91 785.2 91C756 91 727 91 698 91C669 91 640 91 611 91C582 91 553 91 523.8 91C494.7 91 465.3 91 436.2 91C407 91 378 91 349 91C320 91 291 91 262 91C233 91 204 91 174.8 91C145.7 91 116.3 91 87.2 91C58 91 29 91 14.5 91L0 91Z"
          className="shape-fill"
          style={{ transition: "all 0.4s cubic-bezier(0.16, 1, 0.3, 1) 0s" }}
        ></path>
      </svg>
    </div>
  </>
);

const BlurredBlob = ({ smallScreen }: { smallScreen?: boolean }) => (
  <div className="blurred-blob filled-wave-container" style={{ height: "100%", zIndex: 0 }}>
    <svg id="visual" viewBox="0 0 960 600" style={{ height: "100%" }} preserveAspectRatio="none">
      <defs>
        <filter id="blob-blur-filter">
          <feGaussianBlur in="SourceGraphic" stdDeviation="30" />
        </filter>
      </defs>
      <g
        transform="translate(520 230)"
        style={{
          transition: "all 0.4s cubic-bezier(0.16, 1, 0.3, 1) 0s",
          filter: "url(#blob-blur-filter)",
        }}
      >
        <path
          d="M206.9 -211.2C253.5 -207.4 266.6 -128.4 291.5 -43.1C316.4 42.1 353 133.6 330.7 208.5C308.4 283.4 227 341.9 159.7 311C92.3 280 39 159.7 -38.7 123.9C-116.4 88 -218.4 136.5 -273 119.9C-327.5 103.2 -334.6 21.5 -281.2 -9.3C-227.8 -40.2 -113.9 -20.1 -57 -22C0 -24 0 -48 40.1 -95.8C80.1 -143.5 160.3 -215 206.9 -211.2"
          style={{
            transition: "all 0.4s cubic-bezier(0.16, 1, 0.3, 1) 0s",
            scale: smallScreen ? "-1.4 1.1" : "-1 1.1",
          }}
        ></path>
      </g>
    </svg>
  </div>
);

const DiagonalTransition = ({ smallScreen }: { smallScreen?: boolean }) => (
  <div
    className="diagonal-divider filled-wave-container"
    style={{
      height: "100%",
      zIndex: 0,
      translate: "0 -50.25%",
      rotate: "180deg",
      top: 0,
    }}
  >
    <svg id="visual" viewBox="0 0 960 600" preserveAspectRatio="none">
      <defs>
        <filter id="diagonal-blur-filter">
          <feGaussianBlur in="SourceGraphic" stdDeviation="15" />
        </filter>
      </defs>
      <g
        transform="translate(0 -300)"
        style={{
          transition: "all 0.4s cubic-bezier(0.16, 1, 0.3, 1) 0s",
          filter: "url(#diagonal-blur-filter)",
        }}
      >
        <path
          d="M0 506L17.8 510C35.7 514 71.3 522 106.8 522.3C142.3 522.7 177.7 515.3 213.2 517.7C248.7 520 284.3 532 320 540.8C355.7 549.7 391.3 555.3 426.8 549C462.3 542.7 497.7 524.3 533.2 522.2C568.7 520 604.3 534 640 540.5C675.7 547 711.3 546 746.8 548.2C782.3 550.3 817.7 555.7 853.2 552.2C888.7 548.7 924.3 536.3 942.2 530.2L960 524L960 601L942.2 601C924.3 601 888.7 601 853.2 601C817.7 601 782.3 601 746.8 601C711.3 601 675.7 601 640 601C604.3 601 568.7 601 533.2 601C497.7 601 462.3 601 426.8 601C391.3 601 355.7 601 320 601C284.3 601 248.7 601 213.2 601C177.7 601 142.3 601 106.8 601C71.3 601 35.7 601 17.8 601L0 601Z"
          style={{
            transformOrigin: "center bottom",
            scale: smallScreen ? "1 0.75" : "1 1.25",
            fill: "#ffb8c7",
          }}
        ></path>
        <path
          d="M0 506L17.8 510C35.7 514 71.3 522 106.8 522.3C142.3 522.7 177.7 515.3 213.2 517.7C248.7 520 284.3 532 320 540.8C355.7 549.7 391.3 555.3 426.8 549C462.3 542.7 497.7 524.3 533.2 522.2C568.7 520 604.3 534 640 540.5C675.7 547 711.3 546 746.8 548.2C782.3 550.3 817.7 555.7 853.2 552.2C888.7 548.7 924.3 536.3 942.2 530.2L960 524L960 601L942.2 601C924.3 601 888.7 601 853.2 601C817.7 601 782.3 601 746.8 601C711.3 601 675.7 601 640 601C604.3 601 568.7 601 533.2 601C497.7 601 462.3 601 426.8 601C391.3 601 355.7 601 320 601C284.3 601 248.7 601 213.2 601C177.7 601 142.3 601 106.8 601C71.3 601 35.7 601 17.8 601L0 601Z"
          style={{
            transformOrigin: "center bottom",
            rotate: "180deg",
            scale: smallScreen ? "1 0.75" : "1 1.25",
            fill: "#ffb8c7",
          }}
        ></path>
      </g>
    </svg>
  </div>
);

const DiagonalWave = ({ smallScreen }: { smallScreen?: boolean }) => (
  <>
    <div className="diagonal-wave filled-wave-container" style={{ height: "100%" }}>
      <svg
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 960 400"
        preserveAspectRatio="none"
        style={{
          height: smallScreen ? "250%" : "350%",
          top: smallScreen ? "-75%" : "-120%",
          transform: "rotate(180deg)",
          zIndex: 0,
        }}
      >
        <path
          d="M0 265L26.7 256.7C53.3 248.3 106.7 231.7 160 224.2C213.3 216.7 266.7 218.3 320 217.8C373.3 217.3 426.7 214.7 480 207.5C533.3 200.3 586.7 188.7 640 183.2C693.3 177.7 746.7 178.3 800 175.2C853.3 172 906.7 165 933.3 161.5L960 158L960 401L933.3 401C906.7 401 853.3 401 800 401C746.7 401 693.3 401 640 401C586.7 401 533.3 401 480 401C426.7 401 373.3 401 320 401C266.7 401 213.3 401 160 401C106.7 401 53.3 401 26.7 401L0 401Z"
          opacity="0.4"
          className="shape-fill"
          style={{
            transition: "all 0.4s cubic-bezier(0.16, 1, 0.3, 1) 0s",
          }}
        ></path>
      </svg>
    </div>
  </>
);

// New to python? Efficalc is the perfect opportunity to learn while being practical and productive. Intuitive syntax makes it easy to write some of the best calcs you ever have.

export default function HomePage() {
  const theme = useTheme();
  const xlBreakPoint = useMediaQuery(theme.breakpoints.up("xl"));
  const lgBreakPoint = useMediaQuery(theme.breakpoints.up("lg"));
  const mdBreakPoint = useMediaQuery(theme.breakpoints.up("md"));
  const xsBreakPoint = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box sx={{ padding: "0px" }}>
        <ColoredBox
          fillColor="secondary"
          id="hero-section-background"
          sx={{ position: "relative" }}
        >
          <WavyBottom smallScreen={!mdBreakPoint} />
          <HomePageContainer maxWidth="xl">
            <Stack
              direction={{ xs: "column", md: "row" }}
              spacing={{ xs: 4, md: 6 }}
              marginTop={{ xs: "1rem", md: "3rem" }}
              justifyContent={{ xs: "flex-start", md: "space-around" }}
              alignItems="center"
              maxWidth={theme.breakpoints.values.xl}
            >
              <Stack direction="column" maxWidth="700px" spacing={{ xs: 2, md: 6 }}>
                <Typography variant="h2" fontSize="2.8rem" fontWeight="bolder">
                  Streamlined calculations
                  <br />
                  for efficient workflows
                </Typography>

                <Typography maxWidth="500px" paddingLeft={{ xs: 0, md: "5px" }}>
                  Our easy-to-use platform immediately turns your equations into an optimized design
                  interface and submittal-ready calculation report.
                </Typography>
                <Box width="100%" textAlign="center">
                  <Link to={routes.register.path}>
                    <Button
                      variant="contained"
                      sx={{ paddingBlock: "0.5rem", paddingInline: "1rem", fontSize: "1rem" }}
                    >
                      Get started for free!
                    </Button>
                  </Link>
                </Box>
              </Stack>
              <Box
                padding="0px"
                margin="0px"
                itemProp="subjectOf"
                itemScope
                itemType="https://schema.org/VideoObject"
              >
                <meta itemProp="name" content="What is efficalc? | Explainer Video" />
                <meta
                  itemProp="description"
                  content="Write real variable names and transparent equations, then efficalc immediately formats an easily-reviewed calculation report and optimized design interface."
                />
                <meta itemProp="thumbnailUrl" content="/efficalc_explainer_thumbnail.jpg" />
                <meta itemProp="uploadDate" content="2023-04-15T08:00:00+08:00" />
                <meta itemProp="duration" content="T1M10S" />
                <meta itemProp="embedUrl" content="https://www.youtube.com/embed/dI56t94r6pE" />
                <iframe
                  width={
                    xlBreakPoint
                      ? "700"
                      : lgBreakPoint
                      ? "560"
                      : mdBreakPoint
                      ? "448"
                      : xsBreakPoint
                      ? "336"
                      : "560"
                  }
                  height={
                    xlBreakPoint
                      ? "394"
                      : lgBreakPoint
                      ? "315"
                      : mdBreakPoint
                      ? "252"
                      : xsBreakPoint
                      ? "189"
                      : "315"
                  }
                  src="https://www.youtube-nocookie.com/embed/dI56t94r6pE"
                  title="What is efficalc? | Explainer Video"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </Box>
            </Stack>

            <Typography textAlign="center" marginTop="10vh" display={{ xs: "none", md: "initial" }}>
              <KeyboardDoubleArrowDownIcon fontSize="large" />
            </Typography>
          </HomePageContainer>
        </ColoredBox>
        <Container maxWidth="lg" sx={{ paddingBottom: "2rem" }}>
          <DemoRow>
            <DemoText variant="h3" id="gradient-text-1">
              Write calculations faster than ever! Avoid tedious spreadsheet formatting.
            </DemoText>
            <DemoImage
              src="/images/template_builder.jpg"
              alt="Efficalc Template Builder"
            ></DemoImage>
          </DemoRow>

          <DemoRow>
            <DemoText variant="h3" id="gradient-text-2" display={{ xs: "block", sm: "none" }}>
              Auto-generated design pages for every calculation help you optimize each design.
            </DemoText>
            <DemoImage src="/images/design_portal.jpg" alt="Efficalc Design Portal"></DemoImage>
            <DemoText
              variant="h3"
              id="gradient-text-2"
              textAlign="right"
              display={{ xs: "none", sm: "block" }}
            >
              Auto-generated design pages for every calculation help you optimize each design.
            </DemoText>
          </DemoRow>

          <DemoRow>
            <DemoText variant="h3" id="gradient-text-3">
              Effortlessly reuse your calculation templates hundreds of times.
            </DemoText>
            <DemoImage src="/images/full_workflow.jpg" alt="Efficalc Workflow 1"></DemoImage>
          </DemoRow>
        </Container>

        <ColoredBox
          fillColor="primary"
          paddingTop="15vh"
          paddingBottom="25vh"
          boxShadow="0 7px 10px rgba(0,0,0,.3)"
          sx={{ position: "relative" }}
        >
          <DiagonalWave smallScreen={!mdBreakPoint} />
          <Container maxWidth="md" style={{ zIndex: 1 }}>
            <BigHeader variant="h2" display="flex" alignItems="center" gutterBottom flexWrap="wrap">
              More transparency{" "}
              <ArrowForwardIcon fontSize="inherit" sx={{ marginInline: "1rem" }} /> less hassle
            </BigHeader>
            <Typography variant="h5" fontWeight="normal" gutterBottom>
              With highly readable calculations and automatic calculation reports you will:
            </Typography>
            <IconBullet>
              <PageIcon src="/icons/page_review.svg" alt="Review Icon" /> Easily verify your
              calculations. Confusing yourself with your own spreadsheets isn't a good look.
            </IconBullet>
            <IconBullet>
              <PageIcon src="/icons/page_share.svg" alt="Share Icon" /> Be your coworkers' best
              friend by sharing calculations that make sense. No one likes using someone's confusing
              spreadsheet.
            </IconBullet>
            <IconBullet>
              <PageIcon src="/icons/page_approve.svg" alt="Approve Icon" /> Get on the good side of
              submittal authorities. They'll never have to ask for more detail, because every step
              is clearly displayed in the calculation report.
            </IconBullet>
          </Container>
        </ColoredBox>

        <Box
          sx={{ paddingTop: "15vh", paddingBottom: "25vh", marginTop: "20px" }}
          id="light-section-background"
        >
          <Container maxWidth="md">
            <BigHeader
              variant="h2"
              fontWeight="bolder"
              gutterBottom
              id="gradient-text-4"
              padding="0.5rem"
            >
              Optimized for modern engineers
            </BigHeader>
          </Container>
          <Container maxWidth="lg" sx={{ marginTop: "5rem" }}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 4, sm: 2 }}
              justifyContent="space-around"
              marginY="2rem"
              alignItems={{ xs: "center", sm: "initial" }}
            >
              <IconCard raised>
                <CardIcon src="/icons/toolkit.svg" alt="Toolkit Icon" />
                <Typography>
                  Our engineering toolkit includes section properties, design checks, and much more.
                </Typography>
              </IconCard>
              <IconCard raised>
                <CardIcon src="/icons/python.svg" alt="Python Icon" />
                <Typography>
                  As the #1 most popular programming language, Python comes with an abundance of
                  powerful libraries and learning resources.
                </Typography>
              </IconCard>
              <IconCard raised>
                <CardIcon src="/icons/cloud_calc.svg" alt="Calculate Icon" />
                <Typography>
                  Our cloud-based platform gives you access to your calculations from any computer,
                  anywhere, any time.
                </Typography>
              </IconCard>
            </Stack>
          </Container>
        </Box>

        <ColoredBox
          fillColor="secondary"
          paddingTop="15vh"
          paddingBottom="25vh"
          style={{ position: "relative" }}
        >
          <BlurredBlob smallScreen={!mdBreakPoint} />

          <Container maxWidth="md" sx={{ zIndex: 1 }}>
            <BigHeader variant="h2" padding="1rem" paddingLeft="0px">
              efficalc in action
            </BigHeader>

            <Typography gutterBottom>
              Not ready to jump in yet? We have a few different experiences to help you see what
              efficalc is all about before you even make a free account!
            </Typography>

            <Typography variant="h4" marginTop={4} gutterBottom>
              Free online calculation tools
            </Typography>
            <Typography component="div" gutterBottom>
              <Typography>
                Take a look at some free example calculations to see what you can build with
                efficalc. These calculation are all:
              </Typography>
              <ul>
                <li>Built 100% with the efficalc library on the efficalc cloud platform.</li>
                <li>
                  Open-source so that you can instantly clone them to your templates and start
                  editing them for your own needs.
                </li>
                <li>
                  Free online calculations with an instant calculation report that you can download
                  or print.
                </li>
              </ul>
            </Typography>
            <Stack
              spacing={{ xs: 2, sm: 4 }}
              direction="row"
              useFlexGap
              flexWrap="wrap"
              justifyContent="space-evenly"
              marginBlock={4}
            >
              <RelatedCalculationTool
                calcName="steel-beam-calculator"
                titleOverride="Steel Beam Capacity"
                text="Beam moment capacity is calculated for your provided demands, beam span, and section size. The designer and calculation report includes the design strength of the beam and a design check against the moment demand."
              />
              <RelatedCalculationTool
                calcName="steel-beam-optimizer"
                titleOverride="Steel Beam Optimizer"
                text="You provide the beam moment demand and span, then our optmizer will calculate the lightest wide flange section size to meet those parameters. This produces a similar calculation report to the Steel Beam Capacity calculation."
              />
            </Stack>

            <Typography variant="h4" marginTop={4} gutterBottom>
              Video demos
            </Typography>
            <Typography marginBottom={4}>
              See the video carousel below or our youtube channel:{" "}
              <a href="https://www.youtube.com/@efficalc" target="_blank" rel="noopener noreferrer">
                youtube.com/@efficalc
              </a>{" "}
              for some simple demos of the efficalc cloud platform.
            </Typography>
            <DemoCarousel />

            <Typography variant="h4" marginTop={4} gutterBottom>
              Documentation and examples
            </Typography>
            <Typography>
              We've compiled extensive documentation to help you learn more about how to use
              efficalc and see all the features you can build with:{" "}
              <a href="https://docs.encompapp.com/" target="_blank" rel="noopener noreferrer">
                docs.encompapp.com
              </a>
            </Typography>
          </Container>
        </ColoredBox>

        <ColoredBox
          fillColor="grey"
          paddingTop="15vh"
          paddingBottom="20vh"
          style={{ position: "relative" }}
        >
          <DiagonalTransition smallScreen={!mdBreakPoint} />
          <Container maxWidth="md" sx={{ zIndex: 1 }}>
            <BigHeader variant="h2" marginBlock="2rem" padding="1rem" paddingLeft="0px">
              First time here?
            </BigHeader>
            <Typography variant="h4" gutterBottom>
              Welcome!
            </Typography>
            <Typography gutterBottom>
              We're excited that you found us and want to make using efficalc as seamless as
              possible.
            </Typography>
            <Box display="flex" marginLeft="2em">
              <QuestionMarkIcon />
              <Typography gutterBottom>
                The <DocumentationHref /> site is full of helpful content for beginners and experts
                alike.
              </Typography>
            </Box>
            <Box display="flex" marginLeft="2em">
              <PersonIcon />
              <Typography gutterBottom>
                You can also <StyledLink to={routes.register.path}>make a free account</StyledLink>{" "}
                and explore on your own.
              </Typography>
            </Box>

            <Typography marginTop="2rem">
              Ditch ambiguous and confusing spreadsheet calcs. <b>Start using efficalc today!</b>
            </Typography>
          </Container>
        </ColoredBox>

        <ColoredBox fillColor="primary" paddingTop="5vh" paddingBottom="15vh">
          <Container maxWidth="md">
            <BigHeader variant="h2" marginBottom="2rem" padding="1rem" paddingLeft="0px">
              Questions / Contact
            </BigHeader>
            <Box>
              <Typography gutterBottom>
                The contact button makes it easy to reachout with any questions or feedback you
                might have: <ContactFormButton inline />
              </Typography>
              <Typography gutterBottom>
                We are continually adding <b>new features</b> to make efficalc better so please let
                us know if there is something new you would like to see.
              </Typography>
            </Box>
          </Container>
        </ColoredBox>
      </Box>
    </>
  );
}
