import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_BASE_URL, apiRunPublicCalculation } from "../api";
import {
  PublicCalcContents,
  PublicCalculationRunDto,
  PublicCalculationRunResponse,
} from "../commonTypes/CalculationT";
import { StatusT } from "../commonTypes/StatusT";
import { RootState } from "../store";
import { errorsActions } from "./errors";

export const GET_RUN_PUBLIC_CALCULATION = "GET_RUN_PUBLIC_CALCULATION";
export const RUN_PUBLIC_CALCULATION = "RUN_PUBLIC_CALCULATION";

///
/// State
///

export interface PublicCalculationState {
  publicCalculationStatus: StatusT;
  publicCalculationRunResults: PublicCalculationRunResponse | null;
  publicCalculationRunStatus: StatusT;
  publicCalculationCodeContents?: PublicCalcContents;
}

export const initialState: PublicCalculationState = {
  publicCalculationStatus: "idle",
  publicCalculationRunResults: null,
  publicCalculationRunStatus: "idle",
  publicCalculationCodeContents: undefined,
};

///
/// Actions
///

const runPublicCalculation = createAsyncThunk(
  RUN_PUBLIC_CALCULATION,
  async (calculationRunDto: PublicCalculationRunDto, thunkApi) => {
    try {
      return await apiRunPublicCalculation(calculationRunDto);
    } catch (err) {
      thunkApi.dispatch(errorsActions.throwError(`${err}`));
      return thunkApi.rejectWithValue(null);
    }
  }
);

///
/// Slice
///

export const publicCalculation = createSlice({
  name: "public-calculation",
  initialState,
  reducers: {
    clearCalculationState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(runPublicCalculation.pending, (state) => {
        state.publicCalculationRunStatus = "loading";
      })
      .addCase(runPublicCalculation.fulfilled, (state, action) => {
        state.publicCalculationRunStatus = "idle";
        state.publicCalculationRunResults = action.payload;
      })
      .addCase(runPublicCalculation.rejected, (state) => {
        state.publicCalculationRunStatus = "failed";
      });
  },
});

export const publicCalculationApi = createApi({
  reducerPath: "publicCalculationApi",
  baseQuery: fetchBaseQuery({ baseUrl: `${API_BASE_URL}` }),
  endpoints: (builder) => ({
    getPublicCalculationContents: builder.query<PublicCalcContents, string>({
      query: (calcName) => `public-calc/${calcName}/contents/`,
    }),
  }),
});

///
/// Exports
///

export const getCalculationStatus = (state: RootState) =>
  state.publicCalculation.publicCalculationStatus;
export const getCalculationRunStatus = (state: RootState) =>
  state.publicCalculation.publicCalculationRunStatus;
export const getCalculationRunResults = (state: RootState) =>
  state.publicCalculation.publicCalculationRunResults;

export const { useGetPublicCalculationContentsQuery } = publicCalculationApi;

export const calculationActions = {
  ...publicCalculation.actions,
  runPublicCalculation,
};
export default publicCalculation.reducer;
