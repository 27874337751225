import {
  Box,
  InputAdornment,
  OutlinedInput,
  Paper,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect } from "react";

import { CalcTable, InputVariable } from "../commonTypes/CalculationRunTypes";
import { wrapMathString } from "./CalcReportComponents/reportUtilities";
import InputSelectItem from "./InputSelectItem";
import InputTableItem from "./InputTableItem";

const SPACER = " \\ ";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:nth-of-type(even)": {
    backgroundColor: "white",
  },
  "&:hover": {
    backgroundColor: theme.palette.primary.light,
  },
}));

const itemToInput = (
  item: InputVariable,
  itemValue: number | string | undefined,
  handleChange: (name: string, value: string | number) => void,
  handleChangeSelect: (event: SelectChangeEvent<string | number>) => void
) =>
  item.inputType === "select" ? (
    <InputSelectItem item={item} itemValue={itemValue} onChange={handleChangeSelect} />
  ) : (
    <OutlinedInput
      required
      name={item.name}
      value={itemValue}
      type={item.inputType}
      inputProps={{
        min: item.minValue,
        max: item.maxValue,
        step: item.numStep,
      }}
      endAdornment={
        item.unit ? (
          <InputAdornment position="end">
            {wrapMathString(`${SPACER}${item.unit}${SPACER}`)}
          </InputAdornment>
        ) : undefined
      }
      onChange={(e) =>
        handleChange(e.target.name, item.inputType === "number" ? +e.target.value : e.target.value)
      }
      margin="none"
      size="small"
      sx={{ backgroundColor: "white" }}
    />
  );

export interface FormValuesT {
  [key: string]: string | number;
}

export interface TableFormValuesT {
  [key: string]: (string | number)[][];
}

interface Props {
  inputItems: InputVariable[];
  updatedInputState: [FormValuesT, React.Dispatch<React.SetStateAction<FormValuesT>>];
  onUpdateInputVals: () => void;
  inputTableState: [CalcTable[], React.Dispatch<React.SetStateAction<CalcTable[]>>];
  inputFigures: JSX.Element[];
}

export default function CalculationInputTable({
  inputItems,
  onUpdateInputVals,
  updatedInputState,
  inputTableState,
  inputFigures,
}: Props) {
  const [formValues, setValues] = updatedInputState;
  const [tables, setTables] = inputTableState;

  const handleInputTableChange = (tableIndex: number, newData: (string | number)[][]) => {
    const convertedData = newData.map((row) =>
      row.map((cell) => {
        const num = +cell;
        return isNaN(num) ? cell : num;
      })
    );

    setTables((existing) => {
      const copy = [...existing];
      copy[tableIndex] = { ...copy[tableIndex], data: convertedData };
      return copy;
    });
    onUpdateInputVals();
  };

  const handleChange = (name: string, value: string | number) => {
    setValues({ ...formValues, [name]: value });
    onUpdateInputVals();
  };

  const handleChangeSelect = (event: SelectChangeEvent<string | number>) => {
    const { name, value } = event.target;
    setValues({ ...formValues, [name]: value });
    onUpdateInputVals();
  };

  useEffect(() => {
    const inputObj = inputItems.reduce((obj, item) => {
      obj[item.name == null ? "" : item.name] = item.value == null ? "" : item.value;
      return obj;
    }, {} as FormValuesT);
    setValues(inputObj);
  }, [inputItems]);

  return (
    <Box>
      <TableContainer component={Paper}>
        <Typography variant="h4" sx={{ marginTop: "1rem", marginLeft: "1rem" }}>
          Inputs
        </Typography>
        <Table size="small" aria-label="inputs table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h6">Description</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">Name</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">Value (units)</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {inputItems.map((item, index) => (
              <StyledTableRow key={"input-row-" + index}>
                <TableCell key={"input-desc" + index}>{item.description}</TableCell>
                <TableCell key={"input-name" + index}>{wrapMathString(item.name ?? "")}</TableCell>
                <TableCell key={"input-value" + index}>
                  {itemToInput(
                    item,
                    formValues[item.name || ""] || item.value,
                    handleChange,
                    handleChangeSelect
                  )}
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {tables &&
          tables.map((table, index) => (
            <InputTableItem
              data={table.data || [[]]}
              setData={(newData: (string | number)[][]) => handleInputTableChange(index, newData)}
              headers={table.headers}
              title={table.title}
              numberedRows={table.numberedRows}
            />
          ))}
        {inputFigures}
      </TableContainer>
    </Box>
  );
}
