import { MenuItem } from "@mui/material";
import { useAppSelector } from "../../hooks";
import { getCalculationRunResults } from "../../reduxSlices/calculation";
import { getCurrentTemplateId } from "../../reduxSlices/template";
import { routes } from "../../routes";
import { NavButton } from "./NavButton";
import { NavBarHref, StyledNavLink } from "./NavComponents";
import ToolsMenu from "./ToolsMenu";

type CommonProps = {
  formatForPopupMenu: boolean;
  handleCloseIconMenu: () => void;
};

const ConditionalWrapper: React.FC<{
  condition: boolean;
  wrapper: (children: React.ReactNode) => JSX.Element;
  children: React.ReactNode;
}> = ({ condition, wrapper, children }) => (condition ? wrapper(children) : <>{children}</>);

const WrapMenuItem = ({
  children,
  handleClickItem,
}: {
  children: React.ReactNode;
  handleClickItem: () => void;
}) => (
  <MenuItem onClick={handleClickItem} sx={{ padding: "0px" }}>
    {children}
  </MenuItem>
);

type NavBarLinkProps = CommonProps & {
  path: string;
  navName: string;
  shouldDisplay: boolean;
};

const NavBarLink: React.FC<NavBarLinkProps> = ({
  path,
  navName,
  shouldDisplay,
  formatForPopupMenu,
  handleCloseIconMenu,
}) =>
  shouldDisplay ? (
    <StyledNavLink to={path} fullWidth={formatForPopupMenu}>
      {({ isActive }) => (
        <NavButton
          isActive={isActive}
          navName={navName}
          handleCloseIconMenu={handleCloseIconMenu}
          dropMenu={formatForPopupMenu}
        />
      )}
    </StyledNavLink>
  ) : null;

type CommonLinkConfig = {
  shouldDisplay: boolean;
};

type LinkConfig = CommonLinkConfig &
  (
    | {
        path: string;
        navName: string;
        external?: boolean;
      }
    | { component: JSX.Element }
  );

type Props = CommonProps & {
  isLoggedIn: boolean;
};

export default function NavLinks({ formatForPopupMenu, handleCloseIconMenu, isLoggedIn }: Props) {
  const currentTemplateId = useAppSelector(getCurrentTemplateId);
  const currentCalcRun = useAppSelector(getCalculationRunResults);
  const isTemplateSelected = currentTemplateId != null;
  const isCalcRunReceived = currentCalcRun != null;

  const links: LinkConfig[] = [
    { path: routes.home.path, navName: "Home", shouldDisplay: true },
    { path: routes.about.path, navName: "About", shouldDisplay: true },
    {
      path: routes.documentation.path,
      navName: "User Guide",
      shouldDisplay: true,
      external: true,
    },
    {
      shouldDisplay: true,
      component: (
        <ToolsMenu
          formatForPopupMenu={formatForPopupMenu}
          handleCloseIconMenu={handleCloseIconMenu}
        />
      ),
    },
    {
      path: routes.login.path,
      navName: "Log In",
      shouldDisplay: !isLoggedIn,
    },
    {
      path: routes.register.path,
      navName: "Register",
      shouldDisplay: !isLoggedIn,
    },
    {
      path: routes.projects.path,
      navName: "Projects",
      shouldDisplay: isLoggedIn,
    },
    {
      path: routes.templates.path,
      navName: "Templates",
      shouldDisplay: isLoggedIn,
    },
    {
      path: routes.templateContent.path(currentTemplateId ?? undefined),
      navName: "Template Editor",
      shouldDisplay: isLoggedIn && isTemplateSelected,
    },
    {
      path: routes.calculation.path(currentCalcRun?.id),
      navName: "Design Portal",
      shouldDisplay: isLoggedIn && isCalcRunReceived,
    },
  ];

  return (
    <>
      {links
        .filter((link) => link.shouldDisplay)
        .map((link) =>
          "component" in link ? (
            link.component
          ) : (
            <ConditionalWrapper
              key={"menu-item-wrapper-" + link.path}
              condition={formatForPopupMenu}
              wrapper={(children) => (
                <WrapMenuItem
                  key={"menu-item-small-" + link.path}
                  handleClickItem={handleCloseIconMenu}
                >
                  {children}
                </WrapMenuItem>
              )}
            >
              {link.external ? (
                <NavBarHref
                  href={link.path}
                  target="_blank"
                  rel="noopener noreferrer"
                  fullWidth={formatForPopupMenu}
                >
                  <NavButton
                    isActive={false}
                    navName={link.navName}
                    handleCloseIconMenu={handleCloseIconMenu}
                    dropMenu={formatForPopupMenu}
                  />
                </NavBarHref>
              ) : (
                <NavBarLink
                  path={link.path}
                  navName={link.navName}
                  shouldDisplay={link.shouldDisplay}
                  formatForPopupMenu={formatForPopupMenu}
                  handleCloseIconMenu={handleCloseIconMenu}
                />
              )}
            </ConditionalWrapper>
          )
        )}
    </>
  );
}
