import { PublicCalcName } from "./commonTypes/CalculationT";

type PublicCalcPath = (id?: PublicCalcName) => string;
type NumberPath = (name?: number) => string;
type StaticPath = string;

export interface RouteT {
  path: StaticPath | PublicCalcPath | NumberPath;
}

export const routes = {
  home: {
    path: "/",
  },
  about: {
    path: "/about",
  },
  documentation: {
    path: "https://docs.encompapp.com/",
  },
  login: {
    path: "/login",
  },
  register: {
    path: "/register",
  },
  projects: {
    path: "/projects",
  },
  templates: {
    path: "/templates",
  },
  templateContent: {
    path: (id?: number) => `/template/${id || ":id"}`,
  },
  calculation: {
    path: (id?: number) => `/calculation/${id || ":id"}`,
  },
  calculationReport: {
    path: (id?: number) => `/calculation-report/${id || ":id"}`,
  },
  templateReport: {
    path: (id?: number) => `/template-report/${id || ":id"}`,
  },
  publicCalculation: {
    path: (name?: PublicCalcName) => `/public-calc/design/${name || ":name"}`,
  },
  publicCalculationReport: {
    path: (name?: PublicCalcName) => `/public-calc/report/${name || ":name"}`,
  },
};

export const routesTypingWithoutSatisfies: Record<string, RouteT> = routes;
