import { Box } from "@mui/material/";
import NavBar from "./nav-bar/NavBar";

// Home bar with logo nad menu options
export default function HomeBar() {
  return (
    <Box marginBottom="0px" marginTop="0px">
      <NavBar />
    </Box>
  );
}
