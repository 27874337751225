import {
  Box,
  CircularProgress,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const SkeletonRow = () => (
  <TableRow>
    <TableCell>
      <Skeleton variant="text" />
    </TableCell>
    <TableCell>
      <Skeleton variant="rounded" width={80} height={42} />
    </TableCell>
    <TableCell>
      <Skeleton variant="rounded" width={165} height={42} />
    </TableCell>
  </TableRow>
);

export default function InputOutputDesignerSkeleton() {
  return (
    <Stack direction={{ xs: "column", md: "row" }} spacing={4} justifyContent="center">
      <Box>
        <TableContainer component={Paper}>
          <Typography variant="h4" sx={{ marginTop: "1rem", marginLeft: "1rem" }}>
            Inputs <CircularProgress size={20} />
          </Typography>
          <Table size="small" aria-label="inputs table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Description</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">Value (units)</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <SkeletonRow />
              <SkeletonRow />
              <SkeletonRow />
              <SkeletonRow />
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box component={Paper} padding="1rem" height="fit-content">
        <Stack direction="row">
          <Typography variant="h4">
            Results <CircularProgress size={20} />
          </Typography>
          <Skeleton variant="text" width={120} />
        </Stack>
        <Box minHeight="10rem" sx={{ position: "relative" }}>
          <Skeleton variant="rounded" width="100%" height={100} />
        </Box>
      </Box>
    </Stack>
  );
}
