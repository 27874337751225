import { Box, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Helmet } from "react-helmet";

const ParagraphBlock = styled(Typography)({
  marginBlockEnd: "1rem",
});

function HeadTags() {
  return (
    <Helmet>
      <title>About efficalc</title>
      <meta
        name="description"
        content="Learn more about how the efficalc platform can streamline your calculation workflow with modern Python-based calculations."
      />
      <meta property="og:title" content="About the efficalc platform" />
      <meta property="og:url" content="https://www.efficalc.com/about" />
      <meta
        property="og:description"
        content="Learn more about how the efficalc platform can streamline your calculation workflow with modern Python-based calculations."
      />
    </Helmet>
  );
}

export default function AboutPage() {
  return (
    <>
      <Container maxWidth="md">
        <HeadTags />
        <Box paddingY="1rem" paddingRight={{ md: "150px" }}>
          <Typography gutterBottom variant="h1">
            About efficalc
          </Typography>
          <ParagraphBlock>
            Efficalc is the engineering calculation platform that combines simplicity and power. Say
            goodbye to complicated spreadsheets and hello to a software that streamlines your
            calculation workflows with ease.
          </ParagraphBlock>
          <Typography variant="h6">Fast and Accurate</Typography>
          <ParagraphBlock>
            Built with engineers in mind, efficalc allows you to build quick and accurate
            calculations that anyone can read and understand. The intuitive design interface and
            project management capabilities will keep your team's calculations organized and
            up-to-date. Plus, the quick feedback loop will empower you to iterate on your designs
            faster than ever.
          </ParagraphBlock>
          <Typography variant="h6">Calculation Reports</Typography>
          <ParagraphBlock>
            We can't forget to mention the huge benefit of our automated calculation reports. No
            more manual formatting of spreadsheets or writing the same equations multiple times for
            review or submittal processes. Calculation reports are handled for you, so you can focus
            on your designs.
          </ParagraphBlock>
          <Typography variant="h6">Powerful</Typography>
          <ParagraphBlock>
            With Efficalc, you have the power of code at your fingertips, enabling you to do more
            with your calculations. Efficalc is designed with the next generation of engineers in
            mind. Its easy-to-learn syntax and modern workflows empower engineers to do more,
            faster. And, with Efficalc's cloud-powered platform, you'll always have access to the
            most recent versions of your calculations.
          </ParagraphBlock>
          <Typography variant="h6">For you!</Typography>
          <ParagraphBlock>
            Efficalc is the perfect solution to minimize the amount of time wasted in the design and
            calculation process. It's simple to use, yet packed with powerful features that will
            transform the way you approach your calculation workflows. Experience the power of
            Efficalc for yourself, start using it for free today.
          </ParagraphBlock>
        </Box>
      </Container>
    </>
  );
}
