import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";

interface StyledNavProps {
  fullWidth: boolean;
}
export const NavBarHref = styled("a", {
  shouldForwardProp: (prop) => prop !== "fullWidth",
})<StyledNavProps>(({ theme, fullWidth }) => ({
  textDecoration: "none",
  width: fullWidth ? "100%" : "auto",
}));

export const StyledNavLink = styled(NavLink, {
  shouldForwardProp: (prop) => prop !== "fullWidth",
})<StyledNavProps>(({ theme, fullWidth }) => ({
  textDecoration: "none",
  width: fullWidth ? "100%" : "auto",
}));
