import { Box } from "@mui/material";
import { FigureBase } from "../commonTypes/CalculationRunTypes";
import { CalcTypography } from "./CalcReportComponents/reportUtilities";

interface Props {
  item: FigureBase;
}

export default function FigureDesigner({ item }: Props) {
  const altText = item.caption || "Calculation figure";
  return item.data ? (
    <Box margin={2} maxWidth="100%">
      <img
        src={`data:image/png;base64,${item.data}`}
        alt={altText}
        style={{ maxWidth: "100%", width: item.isFullWidth ? "100%" : undefined }}
      />
      {item.caption && (
        <CalcTypography variant="caption" color="text.secondary" variantMapping={{ caption: "p" }}>
          {item.caption}
        </CalcTypography>
      )}
    </Box>
  ) : (
    <></>
  );
}
