import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { InputVariable } from "../commonTypes/CalculationRunTypes";

type Props = {
  item: InputVariable;
  itemValue: number | string | undefined;
  onChange: (e: SelectChangeEvent<string | number>) => void;
};

export default function InputSelectItem({ item, itemValue, onChange }: Props) {
  return (
    <Select
      name={item.name}
      value={itemValue}
      onChange={onChange}
      size="small"
      sx={{ minWidth: "10rem", backgroundColor: "white" }}
    >
      {item.selectOptions?.map((option) => (
        <MenuItem value={option}>
          {option} {item.unit && item.unit}
        </MenuItem>
      ))}
    </Select>
  );
}
