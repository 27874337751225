import { configureStore } from "@reduxjs/toolkit";
import auth from "./reduxSlices/auth";
import calculation from "./reduxSlices/calculation";
import errors from "./reduxSlices/errors";
import projects from "./reduxSlices/projects";
import publicCalculation, { publicCalculationApi } from "./reduxSlices/public-calc";
import templates, { templateFromPublicApi } from "./reduxSlices/template";

export const store = configureStore({
  reducer: {
    projects,
    errors,
    auth,
    calculation,
    publicCalculation,
    templates,
    [publicCalculationApi.reducerPath]: publicCalculationApi.reducer,
    [templateFromPublicApi.reducerPath]: templateFromPublicApi.reducer,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(publicCalculationApi.middleware)
      .concat(templateFromPublicApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
