import React, { useEffect, useRef, useState } from "react";

interface PropsT {
  embedCode: string;
  height: number;
  width: number;
}

export default function DemoVideo({ embedCode, height, width }: PropsT) {
  const demoRef = useRef<HTMLIFrameElement>(null);
  const [isDemoVisible, setIsDemoVisible] = useState(false);

  useEffect(() => {
    const observerCallback: IntersectionObserverCallback = (entries) => {
      const [entry] = entries;
      setIsDemoVisible(entry.isIntersecting);
    };
    const observer = new IntersectionObserver(observerCallback);
    const currentRef = demoRef.current;
    if (currentRef) observer.observe(currentRef);

    return () => {
      if (currentRef) observer.unobserve(currentRef);
    };
  }, [demoRef]);

  return (
    <iframe
      ref={demoRef}
      width={width}
      height={height}
      src={
        "https://www.youtube-nocookie.com/embed/" +
        embedCode +
        "?loop=1&mute=1&controls=0&playlist=" +
        embedCode +
        (isDemoVisible ? "&autoplay=1" : "")
      }
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    ></iframe>
  );
}
